@import "https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&family=Ubuntu:wght@400;500;700&display=swap";
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  text-decoration: none;
}

body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: Poppins, sans-serif;
  overflow-x: hidden;
}

html {
  scroll-behavior: smooth;
}

@media only screen and (width <= 1180px) {
  html {
    overflow-x: hidden;
  }
}

section {
  padding: 100px 0;
}

.max-width {
  max-width: 1300px;
  margin: auto;
  padding: 0 80px;
}

.nav-max-width {
  max-width: 100%;
  margin: auto;
  padding: 0 80px;
}

.about .about-content, .projects, .skills .skills-content, .contact .contact-content {
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

section .title {
  text-align: center;
  margin-bottom: 50px;
  padding-bottom: 20px;
  font-size: 2.5rem;
  font-weight: 500;
  position: relative;
}

@media (width <= 991px) {
  .max-width {
    padding: 0 50px;
  }
}

@media (width <= 947px) {
  .max-width {
    max-width: 930px;
  }

  .skills .skills-content .column {
    width: 100%;
    margin-bottom: 35px;
  }
}

@media (width <= 690px) {
  .max-width {
    padding: 0 23px;
  }
}

@media (width <= 500px) {
  .about .about-content .right .text, .skills .skills-content .left .text {
    font-size: 19px;
  }
}

.navbar {
  z-index: 999;
  width: 100%;
  padding: 30px;
  transition: all .3s;
  position: fixed;
}

.navbar.sticky {
  background: #000;
  padding: 15px 0;
}

.navbar .menu {
  text-align: center;
  list-style: none;
}

.navbar .menu li {
  display: inline-block;
}

.navbar .menu li a {
  color: #fff;
  margin-left: 35px;
  font-size: 18px;
  font-weight: 500;
  transition: color .3s;
  display: block;
}

.navbar .menu li a:hover, .navbar.sticky .menu li a:hover {
  color: #8867e4;
}

@media (width <= 947px) {
  .navbar .menu {
    text-align: center;
    background: #111;
    width: 100%;
    height: 100vh;
    padding-top: 80px;
    transition: all .3s;
    position: fixed;
    top: 0;
    left: -100%;
  }

  .navbar .menu.active {
    left: 0;
  }

  .navbar .menu li {
    display: block;
  }

  .navbar .menu li a {
    margin: 20px 0;
    font-size: 25px;
    display: inline-block;
  }
}

.menu-btn {
  color: #fff;
  cursor: pointer;
  font-size: 23px;
  display: none;
}

.scroll-up-btn {
  text-align: center;
  color: #fff;
  z-index: 9999;
  cursor: pointer;
  opacity: 0;
  pointer-events: none;
  background: #533d91;
  border-bottom-width: 2px;
  border-radius: 6px;
  width: 42px;
  height: 45px;
  font-size: 30px;
  line-height: 45px;
  transition: all .3s;
  position: fixed;
  bottom: 10px;
  right: 30px;
}

.scroll-up-btn.show {
  opacity: 1;
  pointer-events: auto;
  bottom: 30px;
}

.scroll-up-btn:hover {
  filter: brightness(90%);
}

@media (width <= 991px) {
  .scroll-up-btn {
    display: none;
  }
}

@media (width <= 947px) {
  .menu-btn {
    z-index: 999;
    margin-left: 1rem;
    display: block;
  }

  .menu-btn i.active:before {
    content: "";
  }
}

.home {
  width: 100lvw;
  height: 100lvh;
  padding: 0;
  position: relative;
}

.home video {
  object-fit: cover;
  width: 100%;
  height: 100%;
  display: flex;
}

.home .home-content {
  color: #fff;
  width: 100%;
  max-width: 1000px;
  position: absolute;
  bottom: 28%;
  left: 15%;
}

.home h1 {
  margin-bottom: 3px;
  font-size: 1.7rem;
  font-weight: 500;
}

.home h2 {
  margin-bottom: 20px;
  font-size: 4.7rem;
  font-weight: 700;
}

.home h3 {
  font-size: 2.5rem;
  font-weight: 500;
}

.home .home-content a {
  color: #fff;
  background: #533d91;
  border-radius: 8px;
  margin-top: 60px;
  padding: 12px 36px;
  font-size: 1.25rem;
  font-weight: 400;
  transition: all .3s;
  display: inline-block;
}

.home .home-content a:hover {
  background: #2f1081;
}

.home .home-content i {
  margin-left: .6rem;
}

@media only screen and (width <= 992px) {
  .home .home-content {
    width: 70%;
    bottom: 35%;
    left: 5%;
  }

  .home h1 {
    font-size: 1.4rem;
  }

  .home h2 {
    font-size: 3rem;
  }

  .home h3 {
    font-size: 1.5rem;
  }
}

.about {
  color: #f3f3f3;
  background: #050816;
}

.about .about-content .left {
  width: 45%;
}

.about .about-content .left img {
  object-fit: cover;
  border-radius: 6px;
  width: 400px;
  height: 400px;
}

.about .about-content .right {
  background-color: #151030;
  border-radius: 15px;
  width: 55%;
  padding: 15px 30px;
}

.about .about-content .right .text {
  margin-top: 10px;
  margin-bottom: 20px;
  font-size: 1.5rem;
  font-weight: 600;
}

.about .about-content .right p {
  margin-bottom: 1rem;
}

.about .about-content .right a {
  color: #fff;
  background: #533d91;
  border-radius: 8px;
  margin-top: 25px;
  margin-bottom: 10px;
  margin-right: 15px;
  padding: 8px 26px;
  font-size: 1.1rem;
  font-weight: 400;
  transition: all .3s;
  display: inline-block;
}

.about .about-content .right a:hover {
  background: #2f1081;
}

.about .about-content .right i {
  margin-left: .5rem;
}

@media (width <= 1104px) {
  .about .about-content .left img {
    width: 350px;
    height: 350px;
  }
}

@media (width <= 947px) {
  .about .about-content .column {
    width: 100%;
  }

  .about .about-content .left {
    justify-content: center;
    margin: 0 auto 60px;
    display: flex;
  }

  .about .about-content .right {
    flex: 100%;
  }
}

.projects, .experience, .skills, .contact {
  color: #fff;
  background: #050816;
}

.projects .title:before, .experience .title:before {
  background: #050816;
}

.projects .container {
  flex-wrap: wrap;
  column-gap: 40px;
  max-width: 1150px;
  margin: 0 auto;
  padding: 0 30px;
  display: flex;
}

.projects .card {
  background: #151030;
  border-radius: 8px;
  flex-direction: column;
  width: 100%;
  max-width: 325px;
  margin-top: 10px;
  padding: 1.5rem;
  display: flex;
  overflow: hidden;
}

.projects .project-preview {
  margin-top: 0;
  object-fit: cover;
  margin-inline: 0;
  background: #edf2f7;
  border-radius: 1rem;
  width: 100%;
  height: 175px;
  margin-bottom: 1.5rem;
  position: relative;
}

.projects .project-preview:hover, .projects .img:hover {
  transform: scale(1.1);
}

.projects .headline {
  margin-top: .5rem;
  margin-inline: 0;
  margin-bottom: 0;
  display: flex;
}

.projects .headline h2 {
  color: #fff;
  margin: 0;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1.2;
}

.projects .libraries {
  margin-top: .5rem;
  margin-inline: 0;
  flex-direction: row;
  align-items: center;
  margin-bottom: .5rem;
  display: flex;
}

.projects .library-vue {
  vertical-align: middle;
  color: #81e6d9;
  background: #81e6d929;
  border-radius: .15rem;
  padding-inline: .25rem;
  font-size: .75rem;
  font-weight: 700;
  display: inline-block;
}

.projects .library-javascript {
  vertical-align: middle;
  margin-top: 0;
  color: #9ae6b4;
  margin-inline: .5rem 0;
  background: #9ae6b429;
  border-radius: .15rem;
  margin-bottom: 0;
  padding-inline: .25rem;
  font-size: .75rem;
  font-weight: 700;
  display: inline-block;
}

.projects .library-html {
  vertical-align: middle;
  color: #e69aad;
  background: #9ae6b429;
  border-radius: .15rem;
  padding-inline: .25rem;
  font-size: .75rem;
  font-weight: 700;
  display: inline-block;
}

.projects .library-python {
  vertical-align: middle;
  color: #5bd65b;
  background: #9ae6b429;
  border-radius: .15rem;
  padding-inline: .25rem;
  font-size: .75rem;
  font-weight: 700;
  display: inline-block;
}

.projects .library-css {
  vertical-align: middle;
  margin-top: 0;
  color: #5471ee;
  margin-inline: .5rem 0;
  background: #fbd38d29;
  border-radius: .15rem;
  margin-bottom: 0;
  padding-inline: .25rem;
  font-size: .75rem;
  font-weight: 700;
  display: inline-block;
}

.projects .library-tailwind {
  vertical-align: middle;
  margin-top: 0;
  color: #fb8dd1;
  margin-inline: .5rem 0;
  background: #fbd38d29;
  border-radius: .15rem;
  margin-bottom: 0;
  padding-inline: .25rem;
  font-size: .75rem;
  font-weight: 700;
  display: inline-block;
}

.projects .library-bootstrap {
  vertical-align: middle;
  margin-top: 0;
  color: #f57474;
  margin-inline: .5rem 0;
  background: #fbd38d29;
  border-radius: .15rem;
  margin-bottom: 0;
  padding-inline: .25rem;
  font-size: .75rem;
  font-weight: 700;
  display: inline-block;
}

.projects .library-nodejs {
  vertical-align: middle;
  margin-top: 0;
  color: #f4fb8d;
  margin-inline: .5rem 0;
  background: #fbd38d29;
  border-radius: .15rem;
  margin-bottom: 0;
  padding-inline: .25rem;
  font-size: .75rem;
  font-weight: 700;
  display: inline-block;
}

.projects .library-postgres {
  vertical-align: middle;
  margin-top: 0;
  color: #fbd38d;
  margin-inline: .5rem 0;
  background: #fbd38d29;
  border-radius: .15rem;
  margin-bottom: 0;
  padding-inline: .25rem;
  font-size: .75rem;
  font-weight: 700;
  display: inline-block;
}

.projects .description {
  margin-top: .5rem;
  color: #c4c0df;
  text-transform: none;
  letter-spacing: 0;
  margin-inline: 0;
  background: none;
  margin-bottom: .5rem;
  padding-bottom: 1rem;
  font-size: .88rem;
  font-weight: 300;
}

.projects .site-link {
  color: #fff;
  cursor: pointer;
  text-align: center;
  background-color: #533d91;
  border-radius: 8px;
  margin-top: 1.1rem;
  padding: .5rem 1rem;
  font-weight: 500;
  text-decoration: none;
}

.projects .site-link:hover {
  background-color: #2f1081;
}

.projects .git-link {
  color: #fff;
  cursor: pointer;
  text-align: center;
  background-color: #533d91;
  border-radius: 8px;
  margin-top: auto;
  padding: .5rem 1rem;
  font-weight: 500;
  text-decoration: none;
}

.projects .git-link:hover {
  background-color: #2f1081;
}

@media only screen and (width <= 1080px) {
  .projects .card {
    width: 100%;
    margin-top: .5rem;
    display: block;
  }
}

.skills .col-container {
  padding-inline: 1rem;
  column-gap: 25px;
  width: 100%;
  max-width: 70rem;
  margin-inline: auto;
  padding: 6.5px;
  display: flex;
}

.skills .col {
  background: #15103066;
  border-radius: .75rem;
  flex: 1;
  padding: 1.5rem;
  box-shadow: 0 0 10px 5px #5e3f7540;
}

.skills .category {
  margin-bottom: 1.5rem;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1.35;
}

.skills .skill-name {
  flex-direction: row;
  align-items: center;
  margin-top: .75rem;
  display: flex;
}

.skills .logo {
  display: inline-block;
}

.skills p {
  letter-spacing: .5px;
  margin-inline-start: .75rem;
  font-size: 1.1rem;
}

.skills p.accordian {
  font-size: 1.1rem;
}

.skills details {
  margin-left: 5px;
}

.skills summary {
  margin-top: .75rem;
}

.skills summary b {
  letter-spacing: .5px;
  vertical-align: super;
  font-size: 1.1rem;
  font-weight: 400;
}

.skills summary::marker {
  color: #fff;
  font-size: 2.2rem;
}

.skills .dropdown {
  display: inline-block;
  position: relative;
}

.skills .dropdown-content {
  z-index: 1;
  background-color: #f1f1f1;
  min-width: 160px;
  display: none;
  position: absolute;
  overflow: auto;
  box-shadow: 0 8px 16px #0003;
}

.skills .show {
  display: block;
}

@media only screen and (width <= 1080px) {
  .skills .col-container {
    flex-direction: column;
    gap: 2rem;
    margin-top: .5rem;
    display: flex;
  }
}

.experience .col-container {
  flex-direction: column;
  max-width: 55rem;
  margin-left: 2.5rem;
  padding: 6.5px;
  display: flex;
}

.experience h2 {
  font-size: 1.5rem;
  font-weight: 600;
}

.experience h3 {
  font-size: 1.2rem;
  font-weight: 500;
}

.experience h4 {
  font-size: 1rem;
  font-weight: 400;
}

.experience ul {
  margin-top: 1.3rem;
  margin-left: 2rem;
}

.experience li {
  padding-bottom: .5rem;
}

.experience .timeline {
  width: 90%;
  max-width: 1170px;
  margin: 0 auto;
  padding: 1.5em 0;
  position: relative;
}

.experience .timeline:before {
  content: "";
  background: #fff;
  width: 4px;
  height: 100%;
  position: absolute;
  top: 0;
}

.experience .timeline-element {
  margin: 4em 0;
  position: relative;
}

.experience .timeline-element:after {
  content: "";
  clear: both;
  display: table;
}

.experience .timeline-element:first-child {
  margin-top: 0;
}

.experience .timeline-element:last-child {
  margin-bottom: 0;
}

.experience .timeline-element-icon {
  visibility: visible;
  background: #fff;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  margin-left: -30px;
  animation: .6s bounce-1;
  position: absolute;
  top: 0;
  left: 0;
}

@keyframes bounce-1 {
  0% {
    opacity: 0;
    transform: scale(.5);
  }

  60% {
    opacity: 1;
    transform: scale(1.2);
  }

  100% {
    transform: scale(1);
  }
}

.experience .timeline-element-logo {
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
}

.experience .timeline-element-logo-img {
  object-fit: contain;
  width: 60%;
  height: 60%;
  display: block;
}

.experience .timeline-element-content {
  visibility: visible;
  background: #15103066;
  border-radius: .75rem;
  margin-left: 7rem;
  padding: 1.5rem;
  position: relative;
  box-shadow: 0 0 10px 5px #5e3f7540;
}

@media (prefers-reduced-motion: no-preference) {
  @supports (animation-timeline: view()) {
    .experience .timeline-element-content {
      animation: linear forwards bounce-2 view();
      animation-range: cover;
    }
  }
}

@keyframes bounce-2 {
  0% {
    opacity: 0;
    transform: translate(-100px);
  }

  60% {
    opacity: 1;
    transform: translate(20px);
  }

  100% {
    transform: translate(0);
  }
}

.experience .timeline-element-arrow {
  content: "";
  border: 7px solid #0000;
  border-right-color: #232631;
  width: 0;
  height: 0;
  position: absolute;
  top: 24px;
  left: auto;
  right: 100%;
  transform: rotate(0);
}

@media only screen and (width <= 992px) {
  .experience .timeline {
    width: 100%;
  }

  .experience .timeline-element-icon {
    width: 50px;
    height: 50px;
    margin-left: -23px;
    animation: none !important;
  }

  .experience .timeline-element-content {
    margin-left: 3rem;
    animation: none !important;
  }
}

.contact .contact-content .column {
  width: calc(50% - 30px);
}

.contact .contact-content .text {
  margin-bottom: 10px;
  font-size: 1.5rem;
  font-weight: 600;
}

.contact .contact-content .left p {
  text-align: justify;
}

.contact .contact-content .left .icons {
  margin: 10px 0;
}

.contact .contact-content .row {
  align-items: center;
  height: 65px;
  display: flex;
}

.contact .contact-content .row .info {
  margin-left: 30px;
}

.contact .contact-content .row i {
  color: #533d91;
  font-size: 25px;
}

.contact .contact-content .info .head {
  font-size: 1.2rem;
  font-weight: 500;
}

.contact .contact-content .info .sub-title {
  color: #c8c5d1;
  font-size: 1.1rem;
}

.contact .contact-content .info a {
  color: #c8c5d1;
}

.contact .contact-content .info a:hover {
  color: #533d91;
}

.contact .right form .fields {
  display: flex;
}

.contact .right form .field, .contact .right form .fields .field {
  width: 100%;
  height: 45px;
  margin-bottom: 15px;
}

.contact .right form .textarea {
  width: 100%;
  height: 80px;
}

.contact .right form .name {
  margin-right: 10px;
}

.contact .right form .field input, .contact .right form .textarea textarea {
  border: 1px solid #d3d3d3;
  border-radius: 6px;
  outline: none;
  width: 100%;
  height: 100%;
  padding: 0 15px;
  font-size: 1rem;
  transition: all .3s;
}

.contact .right form .field input:focus, .contact .right form .textarea textarea:focus {
  border-color: #4316e6;
  box-shadow: 0 0 8px 6px #533d91;
}

.contact .right form .textarea textarea {
  resize: none;
  padding-top: 10px;
}

.contact .right form .textarea textarea#message {
  font-family: Poppins, sans-serif;
}

.contact .right form .button-area {
  align-items: center;
  display: flex;
}

.contact form button {
  cursor: pointer;
  color: #fff;
  background-color: #533d91;
  border: none;
  border-radius: 8px;
  outline: none;
  padding: .75rem 1.5rem;
  font-size: 1.1rem;
  font-weight: 400;
  text-decoration: none;
  transition: all .3s;
}

.contact form button:hover {
  background-color: #2f1081;
}

.contact ::placeholder {
  font-family: Poppins, sans-serif;
  font-size: 1.1rem;
}

.contact .code-cats {
  border-radius: 10px;
  width: 100%;
  max-width: 600px;
  height: auto;
  margin-top: 9%;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.contact figcaption {
  text-align: center;
  color: #c8c5d1;
  font-style: italic;
}

@media only screen and (width <= 768px) {
  .contact .contact-content {
    flex-direction: column;
  }

  .contact .contact-content .column {
    width: auto;
    margin-bottom: 35px;
  }

  .contact .contact-content .left {
    margin-left: 2rem;
  }
}

@media (width <= 500px) {
  .contact .right form .fields {
    flex-direction: column;
  }

  .contact .right form .name, .contact .right form .email {
    margin: 0;
  }

  .right form .error-box {
    width: 150px;
  }
}

footer {
  color: #fff;
  text-align: center;
  background: #000;
  padding: 15px 23px;
}

footer span a {
  color: #8c6fdb;
  text-decoration: none;
}

footer span a:hover {
  text-decoration: underline;
}
/*# sourceMappingURL=index.21fc1292.css.map */
