/*  import google fonts */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&family=Ubuntu:wght@400;500;700&display=swap');

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    text-decoration: none;
}
body {
    font-family: 'Poppins', sans-serif;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    overflow-x: hidden; 
}
html{
    scroll-behavior: smooth;
}

@media only screen and (max-width: 1180px) {
    html {
        overflow-x: hidden;
    }
}
/* all similar content styling codes */
section{
    padding: 100px 0;
}
.max-width{
    max-width: 1300px;
    padding: 0 80px;
    margin: auto;
}
.nav-max-width{
    max-width: 100%;
    margin: auto;
    padding: 0 80px;
}
.about .about-content,
.projects ,
.skills .skills-content,
.contact .contact-content{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
}
section .title{
    position: relative;
    text-align: center;
    font-size: 2.5rem;
    font-weight: 500;
    margin-bottom: 50px;
    padding-bottom: 20px;
}
@media (max-width: 991px) {
    .max-width{
        padding: 0 50px;
    }
}
@media (max-width: 947px){
    .max-width {
        max-width: 930px;
    }
    .skills .skills-content .column
    {
        width: 100%;
        margin-bottom: 35px;
    }
}
@media (max-width: 690px) {
    .max-width{
        padding: 0 23px;
    }
}
@media (max-width: 500px) {
    .about .about-content .right .text,
    .skills .skills-content .left .text{
        font-size: 19px;
    }
}

/* navbar styling */
.navbar {
    position: fixed;
    width: 100%;
    z-index: 999;
    padding: 30px;
    transition: all 0.3s ease;
}
.navbar.sticky{
    padding: 15px 0;
    background: rgb(0, 0, 0);
}
.navbar .menu {
    list-style: none;
    text-align: center;
}
.navbar .menu li {
    display: inline-block;
}
.navbar .menu li a {
    display: block;
    color: #fff;
    font-size: 18px;
    font-weight: 500;
    margin-left: 35px;
    transition: color 0.3s ease;
}
.navbar .menu li a:hover {
    color: rgb(136, 103, 228);
}
.navbar.sticky .menu li a:hover {
    color: rgb(136, 103, 228);
}
@media (max-width: 947px){
    .navbar .menu {
        position: fixed;
        height: 100vh;
        width: 100%;
        left: -100%;
        top: 0;
        background: #111;
        text-align: center;
        padding-top: 80px;
        transition: all 0.3s ease;
    }
    .navbar .menu.active{
        left: 0;
    }
    .navbar .menu li {
        display: block;
    }
    .navbar .menu li a{
        display: inline-block;
        margin: 20px 0;
        font-size: 25px;
    }
}

/* menu button styling */
.menu-btn{
    color: #fff;
    font-size: 23px;
    cursor: pointer;
    display: none;
}
.scroll-up-btn{
    position: fixed;
    height: 45px;
    width: 42px;
    background: rgb(83, 61, 145);
    right: 30px;
    bottom: 10px;
    text-align: center;
    line-height: 45px;
    color: #fff;
    z-index: 9999;
    font-size: 30px;
    border-radius: 6px;
    border-bottom-width: 2px;
    cursor: pointer;
    opacity: 0;
    pointer-events: none;
    transition: all 0.3s ease;
}
.scroll-up-btn.show{
    bottom: 30px;
    opacity: 1;
    pointer-events: auto;
}
.scroll-up-btn:hover{
    filter: brightness(90%);
}
@media (max-width: 991px) {
    .scroll-up-btn{
        display: none
    }
}
@media (max-width: 947px){
    .menu-btn{
        display: block;
        z-index: 999;
        margin-left: 1rem;
    }
    .menu-btn i.active:before{
        content: "\f00d";
    }
}

/* home section styling */
.home{
    width: 100lvw;
    height: 100lvh;
    position: relative;
    padding: 0;
}
.home video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: flex;
}
.home .home-content {
    position: absolute;
    bottom: 28%;
    left: 15%;
    width: 100%;
    max-width: 1000px;
    color: #fff;
}
.home h1 {
    font-size: 1.7rem;
    font-weight: 500;
    margin-bottom: 3px;
}
.home h2 {
    font-size: 4.7rem;
    font-weight: 700;
    margin-bottom: 20px;
}
.home h3 {
    font-size: 2.5rem;
    font-weight: 500;
}
.home .home-content a{
    display: inline-block;
    background: rgb(83, 61, 145);
    color: #fff;
    font-size: 1.25rem;
    padding: 12px 36px;
    margin-top: 60px;
    font-weight: 400;
    border-radius: 8px;
    transition: all 0.3s ease;
}
.home .home-content a:hover{
    background: rgb(47, 16, 129);
}
.home .home-content i {
    margin-left: 0.6rem;
}
@media only screen and (max-width: 992px) {
    .home .home-content {
        width: 70%;
        left: 5%;
        bottom: 35%;
    }
    .home h1 {
        font-size: 1.4rem;
    }
    .home h2 {
        font-size: 3rem;
    }
    .home h3 {
        font-size: 1.5rem;
    }
}

/* about section styling */
.about {
    background: rgb(5, 8, 22);
    color: rgb(243, 243, 243);
}
.about .about-content .left{
    width: 45%;
}
.about .about-content .left img {
    height: 400px;
    width: 400px;
    object-fit: cover;
    border-radius: 6px;
}
.about .about-content .right{
    width: 55%;
    border-radius: 15px;
    background-color:rgb(21, 16, 48);;
    padding: 15px 30px;
}
.about .about-content .right .text{
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 20px;
    margin-top: 10px;
}
.about .about-content .right p{
    margin-bottom: 1rem;
}
.about .about-content .right a{
    display: inline-block;
    background: rgb(83, 61, 145);
    color: #fff;
    font-size: 1.1rem;
    font-weight: 400;
    padding: 8px 26px;
    margin-top: 25px;
    margin-bottom: 10px;
    margin-right: 15px;
    border-radius: 8px;
    transition: all 0.3s ease;
}
.about .about-content .right a:hover{
    background: rgb(47, 16, 129);
}
.about .about-content .right i {
    margin-left: 0.5rem;
}
@media (max-width: 1104px) {
    .about .about-content .left img{
        height: 350px;
        width: 350px;
    }
}
@media (max-width: 947px){
    .about .about-content .column{
        width: 100%;
    }
    .about .about-content .left{
        display: flex;
        justify-content: center;
        margin: 0 auto 60px;
    }
    .about .about-content .right{
        flex: 100%;
    }
}

/* projects section styling */
.projects, .experience, .skills, .contact{
    color:#fff;
    background: rgb(5 8 22);
}
.projects .title::before,
.experience .title::before{
    background: rgb(5 8 22);
} 
.projects .container {
    display: flex;
    max-width: 1150px;
    margin: 0 auto;
    padding: 0 30px;
    column-gap: 40px;
    flex-wrap: wrap;
}
.projects .card {
    background: rgb(21, 16, 48);
    border-radius: 8px;
    padding: 1.5rem;
    margin-top: 10px;
    max-width: 325px;
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}
.projects .project-preview {
    height: 175px;
    width: 100%;
    background: #EDF2F7;
    margin-top: 0px;
    margin-inline: 0px;
    border-radius: 1rem;
    margin-bottom: 1.5rem;
    position: relative;
    object-fit: cover;
}
.projects .project-preview:hover {
    transform: scale(1.1);
}
.projects .img:hover {
    transform: scale(1.1);
}
.projects .headline {
    margin-top: 0.5rem;
    margin-inline: 0px;
    margin-bottom: 0px;
    display: flex;
}
.projects .headline h2 {
    line-height: 1.2;
    font-weight: 700;
    font-size: 1.5rem;
    color:rgb(255, 255, 255);
    margin: 0px;
}
.projects .libraries {
    margin-top: 0.5rem;
    margin-inline: 0px;
    margin-bottom: 0.5rem;
    display: flex;
    flex-direction: row;
    align-items: center;
}
/* .library{
    display: inline-block;
    vertical-align: middle;
    padding-inline-start: 0.25rem;
    padding-inline-end: 0.25rem;
    font-size: 0.75rem;
    border-radius: 0.15rem;
    font-weight: 700;
} */
.projects .library-vue{
    display: inline-block;
    vertical-align: middle;
    padding-inline-start: 0.25rem;
    padding-inline-end: 0.25rem;
    font-size: 0.75rem;
    border-radius: 0.15rem;
    font-weight: 700;
    background: rgba(129, 230, 217, 0.16);
    color: #81E6D9;
}
.projects .library-javascript {
    display: inline-block;
    vertical-align: middle;
    padding-inline-start: 0.25rem;
    padding-inline-end: 0.25rem;
    margin-top: 0px;
    margin-inline: 0.5rem 0px;
    margin-bottom: 0px;
    font-size: 0.75rem;
    border-radius: 0.15rem;
    font-weight: 700;
    background: rgba(154, 230, 180, 0.16);
    color: #9AE6B4;
}
.projects .library-html {
    display: inline-block;
    vertical-align: middle;
    padding-inline-start: 0.25rem;
    padding-inline-end: 0.25rem;
    font-size: 0.75rem;
    border-radius: 0.15rem;
    font-weight: 700;
    background: rgba(154, 230, 180, 0.16);
    color: #e69aad;
}
.projects .library-python {
    display: inline-block;
    vertical-align: middle;
    padding-inline-start: 0.25rem;
    padding-inline-end: 0.25rem;
    font-size: 0.75rem;
    border-radius: 0.15rem;
    font-weight: 700;
    background: rgba(154, 230, 180, 0.16);
    color: #5bd65b;
}
.projects .library-css {
    display: inline-block;
    vertical-align: middle;
    padding-inline-start: 0.25rem;
    padding-inline-end: 0.25rem;
    margin-top: 0px;
    margin-inline: 0.5rem 0px;
    margin-bottom: 0px;
    font-size: 0.75rem;
    border-radius: 0.15rem;
    font-weight: 700;
    background: rgba(251, 211, 141, 0.16);
    color: #5471ee;
}
.projects .library-tailwind {
    display: inline-block;
    vertical-align: middle;
    padding-inline-start: 0.25rem;
    padding-inline-end: 0.25rem;
    margin-top: 0px;
    margin-inline: 0.5rem 0px;
    margin-bottom: 0px;
    font-size: 0.75rem;
    border-radius: 0.15rem;
    font-weight: 700;
    background: rgba(251, 211, 141, 0.16);
    color: #fb8dd1;
}
.projects .library-bootstrap {
    display: inline-block;
    vertical-align: middle;
    padding-inline-start: 0.25rem;
    padding-inline-end: 0.25rem;
    margin-top: 0px;
    margin-inline: 0.5rem 0px;
    margin-bottom: 0px;
    font-size: 0.75rem;
    border-radius: 0.15rem;
    font-weight: 700;
    background: rgba(251, 211, 141, 0.16);
    color: #f57474;
}
.projects .library-nodejs {
    display: inline-block;
    vertical-align: middle;
    padding-inline-start: 0.25rem;
    padding-inline-end: 0.25rem;
    margin-top: 0px;
    margin-inline: 0.5rem 0px;
    margin-bottom: 0px;
    font-size: 0.75rem;
    border-radius: 0.15rem;
    font-weight: 700;
    background: rgba(251, 211, 141, 0.16);
    color: #f4fb8d;
}
.projects .library-postgres {
    display: inline-block;
    vertical-align: middle;
    padding-inline-start: 0.25rem;
    padding-inline-end: 0.25rem;
    margin-top: 0px;
    margin-inline: 0.5rem 0px;
    margin-bottom: 0px;
    font-size: 0.75rem;
    border-radius: 0.15rem;
    font-weight: 700;
    background: rgba(251, 211, 141, 0.16);
    color: #FBD38D;
}
.projects .description {
    margin-top: 0.5rem;
    margin-inline: 0px;
    margin-bottom: 0.5rem;
    color: rgb(196, 192, 223);
    background: none;
    text-transform: none;
    font-weight: 300;
    letter-spacing: 0px;
    font-size: 0.88rem;
    padding-bottom: 1rem;
}
.projects .site-link {
    color: rgb(255, 255, 255);
    font-weight: 500;
    padding: 0.5rem 1rem;
    background-color: rgb(83, 61, 145);
    border-radius: 8px;
    margin-top: 1.1rem;
    cursor: pointer;
    text-decoration: none;
    text-align: center;
}
.projects .site-link:hover {
    background-color: rgb(47, 16, 129);
}
.projects .git-link {
    color: rgb(255, 255, 255);
    font-weight: 500;
    padding: 0.5rem 1rem;
    background-color: rgb(83, 61, 145);
    border-radius: 8px;
    margin-top: auto;
    cursor: pointer;
    text-decoration: none;
    text-align: center;
}
.projects .git-link:hover {
    background-color: rgb(47, 16, 129);
}
@media only screen and (max-width: 1080px) {
    .projects .card { 
        display: block;
        width: 100%;
        margin-top: 0.5rem;
    }
}

/* skills section styling */
.skills .col-container {
    display: flex;
    width: 100%;
    margin-inline: auto;
    max-width: 70rem;
    padding-inline-start: 1rem;
    padding-inline-end: 1rem;
    padding: 6.5px;
    column-gap: 25px;
}
.skills .col {
    flex: 1;
    padding: 1.5rem;
    background: rgba(21, 16, 48, 0.4);
    border-radius: 0.75rem;
    box-shadow: 0px 0px 10px 5px rgb(94, 63, 117, 0.25);
}
.skills .category {
    font-size: 1.5rem;
    font-weight: 700;
    margin-bottom: 1.5rem;
    line-height: 1.35;
}
.skills .skill-name {
    display: flex;
    align-items: center;
    flex-direction: row;
    margin-top: 0.75rem;
}
.skills .logo {
    display: inline-block;
}
.skills p {
    font-size: 1.1rem;
    margin-inline-start: 0.75rem;
    letter-spacing: 0.5px;
}
.skills p.accordian {
    font-size: 1.1rem;
}
.skills details {
    margin-left: 5px;
}
.skills summary {

    margin-top: 0.75rem;
}
.skills summary b {
    font-size: 1.1rem;
    font-weight: 400;
    letter-spacing: 0.5px;
    vertical-align: super;
}   
.skills summary::marker {
    color: white;
    font-size: 2.2rem;
}
.skills .dropdown {
    position: relative;
    display: inline-block;
}
.skills .dropdown-content {
    display: none;
    position: absolute;
    background-color: #f1f1f1;
    min-width: 160px;
    overflow: auto;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
}
.skills .show {
    display: block;
}
@media only screen and (max-width: 1080px) {
    .skills .col-container { 
        display: flex;
        flex-direction: column;
        margin-top: 0.5rem;
        gap: 2rem;
    }
}


/* experience section styling */
.experience .col-container {
    display: flex;
    flex-direction: column;
    margin-left: 2.5rem;
    max-width: 55rem;
    padding: 6.5px;
}
.experience h2 {
    font-size: 1.5rem;
    font-weight: 600;
}
.experience h3 {
    font-size: 1.2rem;
    font-weight: 500;
}
.experience h4 {
    font-size: 1rem;
    font-weight: 400;
}
.experience ul {
    margin-left: 2rem;
    margin-top: 1.3rem;
}
.experience li {
    padding-bottom: .5rem;
}
.experience .timeline {
    width: 90%;
	max-width: 1170px;
	margin: 0 auto;
	position: relative;
	padding: 1.5em 0;
}
.experience .timeline::before {
    content: '';
	position: absolute;
	top: 0;
	height: 100%;
	width: 4px;
	background: white;
}
.experience .timeline-element {
	margin: 4em 0;
	position: relative;
}
.experience .timeline-element::after {
    content: '';
    display: table;
    clear: both;
}
.experience .timeline-element:first-child {
	margin-top: 0;
}
.experience .timeline-element:last-child {
	margin-bottom: 0;
}
.experience .timeline-element-icon {
	background: rgb(255, 255, 255);
	width: 60px;
	height: 60px;
	margin-left: -30px;
	position: absolute;
	top: 0;
	left: 0;
	border-radius: 50%;
	visibility: visible;
	animation: bounce-1 .6s;
}
@keyframes bounce-1 {
	0%{
		opacity: 0;
		transform: scale(0.5);
	}
	60%{
		opacity: 1;
		transform: scale(1.2);
	}
	100%{
		transform: scale(1);
	}
}
.experience .timeline-element-logo {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
}
.experience .timeline-element-logo-img {
	display: block;
	object-fit: contain;
	width: 60%;
	height: 60%;
}
.experience .timeline-element-content {
	position: relative;
	margin-left: 7rem;
	visibility: visible;
    padding: 1.5rem;
    background: rgba(21, 16, 48, 0.4);
    border-radius: 0.75rem;
    box-shadow: 0px 0px 10px 5px rgb(94, 63, 117, 0.25);
}
@media (prefers-reduced-motion: no-preference) {
    @supports (animation-timeline: view()) {
        .experience .timeline-element-content {
            animation: bounce-2 linear forwards;
            animation-timeline: view();
            animation-range: cover;
        }
    }
}
@keyframes bounce-2 {
	0%{
		opacity: 0;
		transform: translate(-100px);
	}
	60%{
		opacity: 1;
		transform: translate(20px);
	}
	100%{
		transform: translate(0);
	}
}
.experience .timeline-element-arrow {
	content: '';
	position: absolute;
	border: 7px solid transparent;
	border-right: 7px solid rgb(35, 38, 49);
	top: 24px;
	left: auto;
	right: 100%;
	height: 0;
	width: 0;
	transform: rotate(0);
}
@media only screen and (max-width: 992px) {
    .experience .timeline {
        width: 100%;
    }
    .experience .timeline-element-icon {
        width: 50px;
        height: 50px;
        margin-left: -23px;
        animation: none !important;
    }
    .experience .timeline-element-content {
        animation: none !important;
        margin-left: 3rem;
    }
}

/* contact section styling */
.contact .contact-content .column{
    width: calc(50% - 30px);
}
.contact .contact-content .text{
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 10px;
}
.contact .contact-content .left p{
    text-align: justify;
}
.contact .contact-content .left .icons{
    margin: 10px 0;
}
.contact .contact-content .row{
    display: flex;
    height: 65px;
    align-items: center;
}
.contact .contact-content .row .info{
    margin-left: 30px;
}
.contact .contact-content .row i{
    font-size: 25px;
    color: rgb(83, 61, 145);
}
.contact .contact-content .info .head{
    font-weight: 500;
    font-size: 1.2rem;
}
.contact .contact-content .info .sub-title{
    color: rgb(200, 197, 209);
    font-size: 1.1rem;
}
.contact .contact-content .info a {
    color: rgb(200, 197, 209);
}
.contact .contact-content .info a:hover {
    color: rgb(83, 61, 145)
}
.contact .right form .fields{
    display: flex;
}
.contact .right form .field,
.contact .right form .fields .field{
    height: 45px;
    width: 100%;
    margin-bottom: 15px;
}
.contact .right form .textarea{
    height: 80px;
    width: 100%;
}
.contact .right form .name{
    margin-right: 10px;
}
.contact .right form .field input,
.contact .right form .textarea textarea{
    height: 100%;
    width: 100%;
    border: 1px solid lightgrey;
    border-radius: 6px;
    outline: none;
    padding: 0 15px;
    font-size: 1rem;
    transition: all 0.3s ease;
}
.contact .right form .field input:focus,
.contact .right form .textarea textarea:focus{
    border-color: #4316e6;
    box-shadow: 0px 0px 8px 6px rgb(83, 61, 145);
}
.contact .right form .textarea textarea{
    padding-top: 10px;
    resize: none;
}
.contact .right form .textarea textarea#message {
    font-family: 'Poppins', sans-serif;
}
.contact .right form .button-area{
    display: flex;
    align-items: center;
}
.contact form button {
    background-color: rgb(83, 61, 145);
    border: none;
    outline: none;
    cursor: pointer;
    border-radius: 8px;
    padding: 0.75rem 1.5rem;
    text-decoration: none;
    color: rgb(255, 255, 255);
    font-size: 1.1rem;
    font-weight: 400;
    transition: all 0.3s ease;
}
.contact form button:hover{
    background-color: rgb(47, 16, 129);
}
.contact ::placeholder {
    font-size: 1.1rem;
    font-family: 'Poppins', sans-serif;
}
.contact .code-cats {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 9%;
    border-radius: 10px;
    width: 100%;
    max-width: 600px;
    height: auto;
}
.contact figcaption {
    text-align: center;
    color: rgb(200, 197, 209);
    font-style: italic;
}
@media only screen and (max-width: 768px) {
    .contact .contact-content {
        flex-direction: column;
    }
    .contact .contact-content .column {
        width: auto;
        margin-bottom: 35px;
    }
    .contact .contact-content .left {
        margin-left: 2rem;
    }
}
@media (max-width: 500px) {
    .contact .right form .fields{
        flex-direction: column;
    }
    .contact .right form .name,
    .contact .right form .email{
        margin: 0;
    }
    .right form .error-box{
        width: 150px;
    }
}

/* footer section styling */
footer{
    background: #000000;
    padding: 15px 23px;
    color: #fff;
    text-align: center;
}
footer span a{
    color: rgb(140, 111, 219);
    text-decoration: none;
}
footer span a:hover{
    text-decoration: underline;
}